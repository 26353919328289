// @ts-nocheck
import { createTheme } from '@mui/material';
import _ from 'lodash';
import baseTheme from './base';
// The palette enables you to modify the color of the components to suit your brand.
// https://material-ui.com/customization/palette/
// https://material-ui.com/customization/default-theme/?expand-path=$.palette
export default createTheme(
  _.merge(baseTheme, {
    themeName: 'SAMS Light',

    palette: {
      type: 'light',
      common: {
        black: '#000',
        white: '#fff',
        blue: '#174a84',
        green: '#0bb7a7',
        red: '#FF2400',
        orange: '#FFBF00',
        lightcyan: '#cff7f2',
        lightgreen: '#5acfc4',
        lightBlue: '#f2f8fc',
        lightergreen: '#f5fcfb',
        lightgray: '#F5F5F5',
        lightgray2: '#00000061',
        lightBlue1: '#1890ff',
        lightgray1: '#878787',
        lightgray3: '#00000014'
      },
      primary: {
        light: '#5acfc4',
        main: '#0bb7a7',
        dark: '#0bb7a7',
        contrastText: '#fff',
      },
      secondary: {
        light: '#e33371',
        main: '#dc004e',
        // dark: will be calculated from palette.secondary.main,
      },
      error: {
        lighter: '#FFE7D9',
        light: '#FFA48D',
        main: '#FF4842',
        dark: '#B72136',
        darker: '#7A0C2E',
        contrastText: '#fff',
      },
      mainDetailsCard: {
        primary: '#CFF7F2',
        secondary: '',
      },
      muiSlider: {
        primary: '#FFA500',
        secondary: '',
      },
      analystCard: {
        primary: '#bddad7',
      },
      descriptionCard: {
        primary: '#FCF0E5',
        secondary: '#ADD8E6',
      },
      text: {
        primary: '#000',
      },
      /* specify custom components style here Eg: <MyComponent /> */
      myComponent: {
        login: {
          bgColor: '#e5e5e5',
        },
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: '#0bb7a7',
            color: '#fff',
            fontWeight: 600,
          },
          stickyHeader: {
            backgroundColor: '#0bb7a7',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          outlined: {
            borderColor: '#0bb7a7',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: '#0bb7a7',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#353935',
          },
        },
      },
    },
  })
);
